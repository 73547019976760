import { React, useEffect, useContext } from 'react';
import { getUserAndRepos } from '../context/github/GithubActions';
import GithubContext from '../context/github/GithubContext'
import RepoList from '../components/repos/RepoList';

function Projects() {
  const { user, repos, dispatch } = useContext(GithubContext)
  const userName = 'adantule';
  useEffect(()=>{
    dispatch({type: 'SET_LOADING'})
    const getUserData = async() =>{
      const userData = await getUserAndRepos(userName)
      dispatch({type:'GET_USER_AND_REPOS', payload:userData})
    }
    getUserData()
  }, [dispatch, userName])

  const {
    name,
    avatar_url,
    bio,
    html_url,
    hireable,
  } = user



  return (
    <>
      <div className='w-full mx-auto lg:w-10/12'>
        <div className='grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 mb-8 md:gap-8'>
          <div className='avatar mb-6 md:mb-0'>
            <div className='rounded-lg card'>
              <figure>
                <img src={avatar_url} alt='' />
              </figure>
            </div>
          </div>

          <div className='col-span-2'>
            <div className='mb-6'>
              <h1 className='text-3xl card-title'>
                {name}
                {hireable && (
                  <div className='mx-1 badge badge-info'>Hireable</div>
                )}
              </h1>
              <p>{bio}</p>
              <div className='mt-4 card-actions'>
                <a
                  href={html_url}
                  target='_blank'
                  rel='noreferrer'
                  className='btn btn-outline'
                >
                  Visit Github Profile
                </a>
              </div>

            </div>
           <RepoList repos={repos} />

          </div>
        </div>
      </div>
    </>
  )
}

export default Projects