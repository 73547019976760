import Navbar from "./components/layout/Navbar"
import Footer from "./components/layout/Footer"
import Contact from "./pages/Contact"
import Home from "./pages/Home"
import Projects from "./pages/Projects"
import NotFound from "./pages/NotFound"
import { GithubProvider } from "./context/github/GithubContext"

import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
function App(){
    return (
    <GithubProvider>
        <Router>
            <div className="flex flex-col justify-between h-screen">
                <Navbar title='Adan Tule'/>
                <main className="container mx-auto px-3 pb-12">
                <Routes>
                    <Route path='/' element={<Home />}/>
                    <Route path='/projects' element={<Projects />}/>
                    <Route path='/contact' element={<Contact />}/>
                    <Route path='/*' element={<NotFound />}/>
                </Routes>
                </main>
            <Footer/> 

            </div>
        </Router>
    </GithubProvider>
    )
}

export default App