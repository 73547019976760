import React from 'react'
import { Link } from 'react-router-dom'
import { FaPersonBooth} from 'react-icons/fa'
function Navbar({title}) {
  return (
    <nav className='navbar mb-12 shadow-lg bg-neutral'>
        <div className="container mx-auto">
            <div className="flex-none px-2 mx-2 text-white">
                <FaPersonBooth className='inline pr-2 text-3xl' />
                <Link to='/' className='text-lg font-bold align-middle'>
                    {title}
                </Link>
            </div>
            <div className="flex-1 px-2 mx-2">
                <div className="flex justify-end text-white">
                    <Link to='/projects' className="btn btn-ghost btn-sm rounded-btn">
                        Projects
                    </Link>
                    <Link to='/contact' className="btn btn-ghost btn-sm rounded-btn">
                        Contact
                    </Link>
                </div>
            </div>
        </div>
    </nav>
  )
}

export default Navbar