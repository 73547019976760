import { React, useEffect, useContext } from 'react';
import { getUserAndRepos } from '../context/github/GithubActions';
import GithubContext from '../context/github/GithubContext';

function Home() {
  const { user, dispatch } = useContext(GithubContext)
  const userName = 'adantule';
  useEffect(()=>{
    dispatch({type: 'SET_LOADING'})
    const getUserData = async() =>{
      const userData = await getUserAndRepos(userName)
      dispatch({type:'GET_USER_AND_REPOS', payload:userData})
    }
    getUserData()
  }, [dispatch, userName])

  const {
    avatar_url,
  } = user
  return (
    <div className='mx-auto px-6 sm:px-8 lg:px-12 xl:px-24 lg:max-w-2lg xl:max-w-1xl'>
      <div className='text-center md:text-left my-6 sm:my-8 md:my-16 lg:my-32 flex flex-col flex-col-reverse md:flex-row'>
      <div className='md:w-1/2'>
        <h1 className='text-5xl lg:text-6xl text-white font-normal'>Web designer</h1>
        <h1 className='text-5xl lg:text-6xl text-black font-normal'>& developer</h1>
        <p className='font-light mt-8 text-lg text-light-purple leading-loose antialiased max-w-md  mx-auto md:mx-0 lg:pr-24"'>
        Adan is a graduate of the University of California, Santa Cruz. 
        With a Bachelor of Arts in Pure Mathematics.
        He is formerly a Software Deveoper at Gomocha USA and Bridgecom(now Previon).
        Adan has worked for 6 years as a web developer, 
        and at points worked as a QA Game Tester and Production Manager. 
        </p>
        </div>
        <div class="md:w-1/2 max-w-xs md:max-w-full mx-auto md:mx-0 mb-12 md:mb-0 md:pl-12">
        <img src={avatar_url} alt='' />
        </div>
      </div>
    </div>
  )
}

export default Home