import React from 'react'

function Contact() {
  return (
<div class="max-w-lg mx-auto py-8 sm:py-16 px-6">
        <h1 class="text-5xl lg:text-6xl text-white font-normal">Contact Me</h1>
        <p class="text-grey-darkest mt-6 sm:mt-12 text-lg sm:text-xl leading-loose antialiased">
            Hello there! Thank you for visting my site. Feel free to send me an email. 
            I do my best to respond in a timely manner, but non-urgent emails may take a few extra days. 
            </p>
    <div class="flex px-2 mx-2 pt-5 place-content-center">
        <a class="link no-underline px-1 mx-1" href="https://linkedin.com/in/adantule">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" aria-labelledby="linkedin" role="presentation" data-v-0020ee1f=""><title id="linkedin" lang="en">linkedin</title> <path fill="currentColor" d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
        </a>
        <a class="link no-underline px-1 mx-1" href="https://github.com/adantule">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 32 32" aria-labelledby="github" role="presentation" data-v-0020ee1f=""><title id="github" lang="en">github</title> <path fill="currentColor" d="M16 0.395c-8.836 0-16 7.163-16 16 0 7.069 4.585 13.067 10.942 15.182 0.8 0.148 1.094-0.347 1.094-0.77 0-0.381-0.015-1.642-0.022-2.979-4.452 0.968-5.391-1.888-5.391-1.888-0.728-1.849-1.776-2.341-1.776-2.341-1.452-0.993 0.11-0.973 0.11-0.973 1.606 0.113 2.452 1.649 2.452 1.649 1.427 2.446 3.743 1.739 4.656 1.33 0.143-1.034 0.558-1.74 1.016-2.14-3.554-0.404-7.29-1.777-7.29-7.907 0-1.747 0.625-3.174 1.649-4.295-0.166-0.403-0.714-2.030 0.155-4.234 0 0 1.344-0.43 4.401 1.64 1.276-0.355 2.645-0.532 4.005-0.539 1.359 0.006 2.729 0.184 4.008 0.539 3.054-2.070 4.395-1.64 4.395-1.64 0.871 2.204 0.323 3.831 0.157 4.234 1.026 1.12 1.647 2.548 1.647 4.295 0 6.145-3.743 7.498-7.306 7.895 0.574 0.497 1.085 1.47 1.085 2.963 0 2.141-0.019 3.864-0.019 4.391 0 0.426 0.288 0.925 1.099 0.768 6.354-2.118 10.933-8.113 10.933-15.18 0-8.837-7.164-16-16-16z"></path></svg>
        </a>
        <a class="link no-underline px-1 mx-1" href="mailto:adantule7@gmail.com">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" aria-labelledby="email" role="presentation" data-v-0020ee1f=""><title id="email" lang="en">email</title> <path fill="currentColor" d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z"/></svg>
        </a>    
    </div>
</div>
  )
}

export default Contact